import axios from "axios";
import { IDoctor } from "../types/models/Doctor";

export const loginDoctor = async (username: string, password: string) => {

  const response = await axios.post("/doctors/sign_in", {
    username, password,
  });

  return response.data;

};

export const getToken = () => {
  return localStorage.getItem("jwt");
}
export const getDoctor = (): IDoctor | null => {
  let doctor = localStorage.getItem("doctor");
  if (!doctor) {
    return null;
  }
  return JSON.parse(doctor);
}